.table td {
  font-size: 13px;
}
.result-info p {
  margin-bottom: 0.3rem !important;
}
.logo {
  margin: 10px auto;
  max-height: 150px;
}
.c-switch {
  margin-bottom: -7px !important;
  margin-left: 10px;
}
.c-switch[class*="-3d"].c-switch-lg
  .c-switch-input:checked
  ~ .c-switch-slider::before {
  transform: translateX(30px);
}

.c-switch[class*="-3d"].c-switch-lg {
  width: 60px;
  height: 25px;
}

.c-switch[class*="-3d"].c-switch-lg .c-switch-slider::before {
  width: 30px;
  height: 25px;
}
.icon-btn {
  cursor: pointer;
}
.icon-btn.trash {
  color: #e55353;
}
.logo-container img {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}
.c-sidebar-brand {
  padding: 10px;
}

.fw-modal .modal-dialog {
  max-width: 98% !important;
}

.add-matches-container {
  min-height: 100px;
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 10px;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
} */

.daterangepicker .drp-buttons::after,
.daterangepicker .drp-buttons::before {
  content: "" !important;
  border: none !important;
  border-color: transparent !important;
}
.daterangepicker {
  top: 40% !important;
}

.cursor-pointer {
  cursor: pointer;
}
.c-frontend-layout .c-body {
  font-size: 13px;
}
.c-frontend-layout .c-header {
  border: none;
}
.c-frontend-layout .form-control {
  border-radius: 1.25em;
}

.c-frontend-layout .form-control:active {
  box-shadow: none;
}
.c-main-frontend {
  padding-top: 0 !important;
  background-color: #ffffff;
}

.site-title-lg {
  font-size: 30px;
}

.site-title-sm {
  font-size: 20px;
}

.site-title {
  color: #f42021;
  font-weight: bold;
}

.wallet-badge {
  background-color: #141414;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 13px;
  color: #ffffff;
  text-align: left;
}

.game-week-wrap {
  overflow: auto;
  white-space: nowrap;
  background-color: #404040;
}
.game-week-wrap a {
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
}
.game-week-wrap a.active,
.game-week-wrap a:hover {
  color: #2eb85c;
  border-bottom: 5px solid #2eb85c;
}

.buy-round-container {
  background-color: #555555;
}
.inkhel-btn-success {
  background: #2eb85c;
  border-color: #2eb85c;
  color: #ffffff;
  font-weight: bold;
}

.color-grey {
  color: #8e8787;
}

.c-frontend-layout .c-sidebar-nav-item a::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: inline-block;
  height: 8px;
  position: relative;
  top: 0;
  left: 0;
  transform: rotate(45deg);
  vertical-align: top;
  width: 8px;
  border-color: #6d6666;
  margin-right: 15px;
}
.menu-title {
  padding: 2em 0 3em 0;
  text-align: center;
}

.league-select-container {
  background-color: #333333;
}
.match-header {
  color: #ffffff;
}
.match-header-blue {
  background-color: #54617b;
}
.match-header-upcoming {
  background-color: #909090;
}
.score-predict {
  background-color: #2eb85c;
}
.winner-predict {
  background-color: #55a4f2;
}
.lose-predict {
  background-color: #f97c7a;
}
.submitted-predict {
  background-color: #152139;
}
.can-predict {
  background-color: #00be7c;
}
.br-0 {
  border-radius: 0;
  border-color: transparent;
}
.box-shadow.card {
  -webkit-box-shadow: 0 9px 6px -6px #ccc;
  -moz-box-shadow: 0 9px 6px -6px #ccc;
  box-shadow: 0 9px 5px -6px #ccc;
}
.text-bold {
  font-weight: bold;
}
.score-col {
  background-color: #ededed;
}
.match-score,
.match-predict {
  display: block;
}
.score-action {
  display: flex;
  align-items: center;
  font-size: 25px;
}
.score-action a {
  /* width: 100%; */
  cursor: pointer;
  color: #3c4c64;
}
.disabled {
  color: #cccccc !important;
}
.match-score {
  color: #49a1d7;
  font-size: 18px;
}
.match-predict-big {
  font-size: 18px;
}
.coin-badge {
  background-color: #e7a74a;
  width: 1em;
  height: 1em;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: bottom;
}
.match-card div.card-body {
  overflow: hidden;
}
.match-card div.card-body,
.match-card div.card-body > div,
.match-card div.card-body > div > div,
.match-card div.card-body > div > div > div {
  height: 60px;
  /* padding: 0 !important; */
}
.name-col,
.vs-col {
  line-height: 45px;
}
.login-link {
  color: #5f5b5b;
  text-transform: uppercase;
}
.vertical-center {
  height: 100%;
  text-align: center;
  align-items: center;
  display: inline-flex;
}
.firebaseui-info-bar-message,
.firebaseui-info-bar,
.firebaseui-id-info-bar {
  display: none !important;
}
.ads {
  background-color: #fff;
  text-align: center;
}
.ads img {
  max-height: 250px;
}
.submit-btn {
  cursor: pointer;
  width: 100%;
  display: inline-block;
}
.alert {
  margin-bottom: 0.5rem;
}
.alert .form-group {
  margin-bottom: 0;
}
.nav-tabs {
  border-bottom: 0;
}
.nav-tabs a {
  font-weight: bold;
  text-transform: uppercase;
  color: #3c4b64;
}
.reward-select {
  font-size: 11px;
  min-width: 100px;
}
.c-main-frontend img{
  max-width: 100%;
}
/* a.page-link{
  background-color: #04acec !important;
    border-color: #04acec !important;
} */
